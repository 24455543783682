var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "device-type-list-component table-component" },
    [
      _c("div", { staticClass: "page-header" }, [
        _c(
          "div",
          { staticClass: "page-breadcrumb" },
          [
            _c(
              "a-breadcrumb",
              [
                _c(
                  "a-breadcrumb-item",
                  [_c("router-link", { attrs: { to: "/" } }, [_vm._v("首页")])],
                  1
                ),
                _c("a-breadcrumb-item", [_vm._v("产品")])
              ],
              1
            )
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "page-body page-body-margin" },
        [
          _c(
            "a-layout",
            [
              _c(
                "a-layout-sider",
                { attrs: { theme: "light" } },
                [
                  _c(
                    "a-card",
                    { attrs: { title: "产品分组", bordered: false } },
                    [
                      _c("jtl-tree-group-component", {
                        attrs: {
                          "group-id": _vm.GroupId,
                          "group-type": _vm.GroupEntityType.DEVICE_TYPE
                        },
                        on: { select: _vm.treeSelect }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c("a-layout-content", [
                _c(
                  "div",
                  { staticClass: "page-body-margin-left-16" },
                  [
                    _c(
                      "a-card",
                      {
                        staticClass: "table-query-card",
                        attrs: { title: "产品查询" }
                      },
                      [
                        _c("a-input-search", {
                          staticStyle: { width: "500px" },
                          attrs: {
                            placeholder: "请输入产品名称",
                            "allow-clear": ""
                          },
                          model: {
                            value: _vm.queryModel.name,
                            callback: function($$v) {
                              _vm.$set(_vm.queryModel, "name", $$v)
                            },
                            expression: "queryModel.name"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "a-card",
                      { attrs: { title: "产品列表" } },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "table-header-button",
                            attrs: { slot: "extra" },
                            slot: "extra"
                          },
                          [
                            _c(
                              "jtl-button",
                              {
                                attrs: {
                                  "table-header-child": "",
                                  type: "primary"
                                },
                                on: { click: _vm.importJson }
                              },
                              [_vm._v("导入")]
                            ),
                            _c(
                              "jtl-button",
                              {
                                attrs: {
                                  "table-header-child": "",
                                  type: "primary"
                                },
                                on: { click: _vm.exportJson }
                              },
                              [_vm._v("导出")]
                            ),
                            _c(
                              "jtl-button",
                              {
                                attrs: {
                                  "table-header-child": "",
                                  type: "primary",
                                  "click-prop": {
                                    callback: _vm.addClick.bind(this),
                                    param: new _vm.DeviceTypeEntityModel(
                                      _vm.GroupId
                                    )
                                  }
                                }
                              },
                              [_vm._v("添加")]
                            )
                          ],
                          1
                        ),
                        _c("a-table", {
                          attrs: {
                            loading: _vm.listLoading,
                            columns: _vm.tableColumns,
                            "data-source": _vm.listData,
                            pagination: _vm.Pagination,
                            scroll: _vm.tableScroll,
                            "row-key": _vm.TableRowKey,
                            size: _vm.TableSize,
                            "expand-icon": function() {},
                            "expanded-row-keys": _vm.expandedRowKeys,
                            "children-column-name": "Children",
                            "row-selection": {
                              selectedRowKeys: _vm.selectedRowKeys,
                              onChange: _vm.onSelectChange,
                              onSelect: _vm.onSelect
                            }
                          },
                          on: { change: _vm.tableChange },
                          scopedSlots: _vm._u([
                            {
                              key: "name",
                              fn: function(text, record) {
                                return [
                                  _c(
                                    "a",
                                    {
                                      on: {
                                        click: function($event) {
                                          return _vm.detailClick(record)
                                        }
                                      }
                                    },
                                    [_vm._v(_vm._s(text))]
                                  )
                                ]
                              }
                            },
                            {
                              key: "action",
                              fn: function(text, record) {
                                return [
                                  _c(
                                    "a",
                                    {
                                      on: {
                                        click: function($event) {
                                          return _vm.detailClick(record)
                                        }
                                      }
                                    },
                                    [_vm._v("查看")]
                                  ),
                                  _c("a-divider", {
                                    attrs: { type: "vertical" }
                                  }),
                                  _c(
                                    "a",
                                    {
                                      on: {
                                        click: function($event) {
                                          return _vm.manageClick(record.id)
                                        }
                                      }
                                    },
                                    [_vm._v("管理设备")]
                                  ),
                                  _c("a-divider", {
                                    attrs: { type: "vertical" }
                                  }),
                                  _c(
                                    "a",
                                    {
                                      staticClass: "jtl-edit-link",
                                      on: {
                                        click: function($event) {
                                          return _vm.editClick(record)
                                        }
                                      }
                                    },
                                    [_vm._v("编辑")]
                                  ),
                                  !record.IsInnerGateway
                                    ? _c(
                                        "span",
                                        [
                                          _c("a-divider", {
                                            attrs: { type: "vertical" }
                                          }),
                                          _c(
                                            "a-popconfirm",
                                            {
                                              attrs: {
                                                title: "确认删除?",
                                                "ok-text": "确认",
                                                "cancel-text": "取消"
                                              },
                                              on: {
                                                confirm: function($event) {
                                                  return _vm.deleteClick(record)
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "a",
                                                { staticClass: "jtl-del-link" },
                                                [_vm._v("删除")]
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ]
                              }
                            }
                          ])
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c("device-type-detail-drawer", { ref: "deviceTypeDetailDrawer" }),
      _c("jtl-entity-dialog", {
        ref: "formDialog",
        on: { dialogOK: _vm.getList }
      }),
      _c("import-json-dialog", {
        ref: "importJsonlDialog",
        on: { dialogOK: _vm.getList }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }