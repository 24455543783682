var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-drawer",
    {
      attrs: {
        title: _vm.drawerTitle,
        placement: _vm.placement,
        width: _vm.width,
        closable: _vm.closable,
        visible: _vm.drawerVisible,
        "destroy-on-close": true
      },
      on: { close: _vm.drawerClose }
    },
    [
      _c(
        "div",
        { staticClass: "device-type-detail-component table-component" },
        [
          _c(
            "div",
            { staticClass: "page-body page-body-bg-white" },
            [
              _c(
                "a-tabs",
                {
                  attrs: { type: "card", "default-active-key": "1" },
                  model: {
                    value: _vm.tabIndex,
                    callback: function($$v) {
                      _vm.tabIndex = $$v
                    },
                    expression: "tabIndex"
                  }
                },
                [
                  _c(
                    "a-tab-pane",
                    { key: "1", attrs: { tab: "产品信息" } },
                    [
                      _c(
                        "a-descriptions",
                        {
                          staticClass: "tab-margin-16",
                          attrs: { bordered: "" }
                        },
                        [
                          _c(
                            "div",
                            { attrs: { slot: "title" }, slot: "title" },
                            [
                              _c(
                                "jtl-button",
                                {
                                  staticClass: "description-title-button",
                                  attrs: { type: "primary" },
                                  on: { click: _vm.editClick }
                                },
                                [_vm._v("编辑")]
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-descriptions-item",
                            { attrs: { label: "产品名称", span: 1 } },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.deviceTypeModel.name) + " "
                              )
                            ]
                          ),
                          _c(
                            "a-descriptions-item",
                            { attrs: { label: "产品标识符", span: 1 } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.deviceTypeModel.identifier) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "a-descriptions-item",
                            { attrs: { label: "是否网关", span: 1 } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.deviceTypeModel.DisplayGateway) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "a-descriptions-item",
                            { attrs: { label: "协议", span: 1 } },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.deviceTypeModel.protocol) + " "
                              )
                            ]
                          ),
                          _c(
                            "a-descriptions-item",
                            { attrs: { label: "创建时间", span: 1 } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("dateFormat")(
                                      _vm.deviceTypeModel.createdTime
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "a-descriptions-item",
                            { attrs: { label: "设备分组", span: 2 } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.deviceTypeModel.groupName) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "a-descriptions-item",
                            { attrs: { label: "关联产品类型", span: 3 } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.deviceTypeModel.supportedDeviceTypeNames
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "a-descriptions-item",
                            { attrs: { label: "描述", span: 3 } },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.deviceTypeModel.remark) + " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-tab-pane",
                    {
                      key: "2",
                      attrs: { tab: "功能定义", "force-render": "" }
                    },
                    [
                      _c("function-index-component", {
                        staticClass: "tab-margin-16",
                        attrs: { "device-type-model": _vm.deviceTypeModel },
                        on: { reload: _vm.reload }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _c("jtl-entity-dialog", {
        ref: "formDialog",
        on: { dialogOK: _vm.reload }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }