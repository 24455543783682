






































































import { Component, Mixins } from 'vue-property-decorator';
import GroupComponent from '@/mixins/group-component';
import TableTreeComponent from '@/mixins/table-tree-component';
import DeviceTypeDetailDrawer from './device-type-detail-drawer.vue';
import ImportJsonDialog from './import-json-dialog.vue';
import TableDialogFormComponent from '@/mixins/table-dialog-form-component';
import { DeviceTypeEntityModel, DeviceTypeQueryModel, ImportJsonEntityModel } from '@/entity-model/device-type-entity';
import DeviceTypeService from '@/service/device-type';
import { DeviceTypeEnum, ViewModeType } from '@/model/enum';

@Component({
    components: {
        'device-type-detail-drawer': DeviceTypeDetailDrawer,
        'import-json-dialog': ImportJsonDialog
    }
})
export default class DeviceTypeListComponent extends Mixins(TableDialogFormComponent, GroupComponent, TableTreeComponent) {
    DeviceTypeEntityModel = DeviceTypeEntityModel;
    DeviceTypeEnum = DeviceTypeEnum;
    created() {
        const deviceTypeQueryModel = new DeviceTypeQueryModel();
        this.initTable({
            service: DeviceTypeService,
            queryModel: deviceTypeQueryModel,
            tableColumns: DeviceTypeEntityModel.getTableColumns()
        });
    }

    treeSelect(groupIds: Array<string>) {
        const groupId = _.first(groupIds);
        (this.queryModel as DeviceTypeQueryModel).groupIds = groupIds;
        if (groupId) {
            this.$router.push(`/dashboard/device-type-list?groupId=${groupId}`);
        } else {
            this.$router.push('/dashboard/device-type-list');
        }
    }

    detailClick(model: DeviceTypeEntityModel) {
        (this.$refs.deviceTypeDetailDrawer as DeviceTypeDetailDrawer).drawerShow(model);
    }
    manageClick(id: string) {
        this.$router.push({
            path: '/dashboard/device-list',
            query: { deviceTypeId: id }
        });
    }

    importJson() {
        const importModel = new ImportJsonEntityModel();
        (this.$refs.importJsonlDialog as ImportJsonDialog).dialogOpen(importModel, null, ViewModeType.NEW);
    }

    exportJson() {
        if (!this.selectedRowKeys || this.selectedRowKeys.length === 0) {
            this.showMessageWarning('请先选择产品');
            return;
        }
        this.startFullScreenLoading('正在导出产品');
        DeviceTypeService.exportDeviceTypeJson({ ids: this.getSelectedRowIds() }).then(res => {
            this.showMessageSuccess('导出产品成功');
        }).catch((err) => {
            throw err;
        }).finally(() => {
            this.stopFullScreenLoading();
        });
    }
}
