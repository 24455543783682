























































import { Component } from 'vue-property-decorator';
import DrawerComponent from '@/mixins/drawer-component';
import { DeviceTypeEntityModel } from '@/entity-model/device-type-entity';
import { ThingsFunctionType, ViewModeType } from '@/model/enum';
import FunctionIndexComponent from './function-index.vue';
import DeviceTypeService from '@/service/device-type';
import FormDialogComponent from '@/mixins/form-dialog-component';

@Component({
    components: {
        'function-index-component': FunctionIndexComponent
    }
})
export default class DeviceTypeDetailDrawer extends DrawerComponent {
    deviceTypeModel: DeviceTypeEntityModel = new DeviceTypeEntityModel();
    tabIndex: string = '1';
    deviceId: string = null;

    drawerShow(model: DeviceTypeEntityModel) {
        this.drawerOpen(model.id, model.name);
        this.init(model.id);
    }

    init(deviceId: string) {
        this.deviceId = deviceId;
        this.startFullScreenLoading();
        DeviceTypeService.retrieve(deviceId)
            .then(res => {
                this.deviceTypeModel = res;
            })
            .catch(error => {
                this.deviceTypeModel = new DeviceTypeEntityModel();
            })
            .finally(() => {
                this.stopFullScreenLoading();
            });
    }

    reload() {
        // this.tabIndex = '1';
        this.init(this.deviceId);
    }
    editClick() {
        (this.$refs.formDialog as FormDialogComponent<DeviceTypeEntityModel>).dialogOpen(this.deviceTypeModel, DeviceTypeService, ViewModeType.UPDATE);
    }
}
