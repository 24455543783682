
















































import { Component, Prop } from 'vue-property-decorator';
import { BaseComponent } from '@/mixins/base-component';
import { ThingsFunctionEntityModel } from '@/entity-model/things-function-entity';
import { AttributeEntityType, ThingsFunctionType, ViewModeType, ThingsEntityType } from '@/model/enum';
import FormDialogComponent from '@/mixins/form-dialog-component';
import ThingsAttributeService from '@/service/things-attribute';
import ThingsEventService from '@/service/things-event';
import ThingsMethodService from '@/service/things-method';
import DeviceTypeService from '@/service/device-type';
import { ThingsAttributeEntityModel } from '@/entity-model/things-attribute-entity';
import { ThingsEventEntityModel } from '@/entity-model/things-event-entity';
import { ThingsMethodEntityModel } from '@/entity-model/things-method-entity';
import ThingsFunctionEditComponent from '../things-function/things-function-edit.vue';
import { DeviceTypeEntityModel } from '@/entity-model/device-type-entity';

@Component({
    components: {
        'things-function-edit-dialog': ThingsFunctionEditComponent
    }
})
export default class FunctionIndexComponent extends BaseComponent {
    AttributeEntityType = AttributeEntityType;
    ThingsFunctionType = ThingsFunctionType;
    ViewModeType = ViewModeType;
    ThingsEntityType = ThingsEntityType;
    currentFunction: ThingsFunctionType = ThingsFunctionType.ATTRIBUTE;

    @Prop()
    deviceTypeModel: DeviceTypeEntityModel;

    get FunctionName(): string {
        return this.deviceTypeModel.getFunctionName(this.currentFunction);
    }

    get TableColumns(): Array<any> {
        return _.filter(
            ThingsFunctionEntityModel.getFunctionTabelColumns(),
            (item: any) => (_.isUndefined(item.functionType) || item.functionType.indexOf(this.currentFunction) > -1)
        );
    }

    get FilterDataList(): Array<any> {
        return this.deviceTypeModel.getFunctionList(this.currentFunction);
    }

    functionItemClick(model: any, viewMode: ViewModeType) {
        switch (this.currentFunction) {
            case ThingsFunctionType.ATTRIBUTE:
                (this.$refs.attributeFormDialog as FormDialogComponent<ThingsAttributeEntityModel>)
                    .dialogOpen(model || new ThingsAttributeEntityModel(ThingsEntityType.DEVICE_TYPE, this.deviceTypeModel.id), ThingsAttributeService, viewMode);
                break;
            case ThingsFunctionType.METHOD:
                (this.$refs.methodFormDialog as FormDialogComponent<ThingsMethodEntityModel>)
                    .dialogOpen(model || new ThingsMethodEntityModel(ThingsEntityType.DEVICE_TYPE, this.deviceTypeModel.id), ThingsMethodService, viewMode);
                break;
            case ThingsFunctionType.EVENT:
                (this.$refs.eventFormDialog as FormDialogComponent<ThingsEventEntityModel>)
                    .dialogOpen(model || new ThingsEventEntityModel(ThingsEntityType.DEVICE_TYPE, this.deviceTypeModel.id), ThingsEventService, viewMode);
                break;
        }
    }

    addFunctionClick() {
        if (this.currentFunction === ThingsFunctionType.FEATURE) {
            (this.$refs.functionEditFormDialog as any).dialogOpen(this.currentFunction, this.FilterDataList);
        } else {
            this.functionItemClick(null, ViewModeType.NEW);
        }
    }

    deleteFunctionClick(model: any) {
        this.startFullScreenLoading('正在删除...');
        this.deviceTypeModel.deleteFunction(this.currentFunction, model, this.deviceTypeModel.id)
            .then(res => {
                this.$emit('reload');
            })
            .finally(() => {
                this.stopFullScreenLoading();
            });
    }

    formDialogOK() {
        this.$emit('reload');
    }

    syncFunctionClick() {
        DeviceTypeService.syncDeviceTypeAttribute(this.deviceTypeModel.id).then(() => {
            this.showMessageSuccess('更新设备成功');
        });
    }
}
